import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Nachlassgericht() {
  return (
    <LayoutXT>
      <h1 className="left">
        Vertretung vor dem Finanzamt oder dem Nachlassgericht /
        Schwarzgeldkonten
      </h1>
      <div className="rem-of-13 gray-a lheight18">
        Sowohl bei der Auseinandersetzung mit dem Finanzamt als auch bei den
        Streitigkeiten vor dem Nachlassgericht begleiten wir Sie kompetent mit
        fundiertem Fachwissen sowie mit Praxiserfahrung.
        <br />
        <br />
        Wir vertreten Ihr Recht in allen erbschaftsteuerrechtlichen
        Streitigkeiten vor dem Finanzamt, so beispielsweise im Rahmen der
        Nachlassbesteuerung. Darüber hinaus beraten wir Sie gerne insbesondere
        bzgl. der Bewertung des Nachlasswerts, Ihrer Steuerklasse und den
        gewährten Freibeträgen. <br />
        <br />
        Auch bei evtl. bislang unbekannten Schwarzgeldkonten im Nachlass ist
        Vorsicht geboten. Wir helfen Ihnen insoweit und werden die notwendige
        Verständigung mit dem Finanzamt leisten, so wird das Schwarzgeldkonto in
        Ihrem Interesse angezeigt und einer Nachversteuerung zugeführt. Sind
        diese Schritte durchgeführt, ist das bislang verborgene Geld
        legalisiert.
        <br />
        <br />
        Auch vor dem Nachlassgericht vertreten wir Ihr Recht, sei es im
        Erbscheinsverfahren, bei der Durchsetzung oder Abwehr von
        Pflichtteilsansprüchen, der Auseinandersetzung von Erbengemeinschaften
        oder bei einer strittigen und anspruchsvollen Auslegung eines
        Testaments. Auch die Durchsetzung von Auskunfts- und
        Wertermittlungsansprüchen gehört zu unserem Leistungsspektrum.
      </div>
    </LayoutXT>
  )
}
